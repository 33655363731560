import React from 'react'
import Seo from '@components/seo'
import Main from '@components/main'
import HeroSection from '@content/hero-section'
import InfoSection from '@content/info-section'
import MessageSection from '@content/message-section'
import BusinessSection from '@content/business-section'
import OverviewSection from '@content/overview-section'
import FindworkSection from '@content/findwork-section'
import ContactSection from '@content/contact-section'

const IndexPage = () => (

  <Main>
    <Seo title="TOP" />
    <HeroSection/>
    <InfoSection/>
    <MessageSection/>
    <OverviewSection/>
    <FindworkSection/>
    <ContactSection/>
    <BusinessSection/>
  </Main>
)

export default IndexPage
