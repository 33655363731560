import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import '@styles/object/project/hero-section.scss'

const HeroSection = () => {

  return (
    <div className="hero-section">
      <div className="hero-section__company-logo-wrapper">
        <StaticImage src="../images/ant_yoko.png" alt="amanect_logo" placeholder="none" height="250" />
      </div>
    </div>
  )
}

export default HeroSection
