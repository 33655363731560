import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import '@styles/object/project/message-section.scss'

const MessageSection = () => {

  return (
    <div id="message" className="message-section">
      <div className="message-section__container-border">
        <article className="message-section__container">
          <h1 className="message-section__title">代表ご挨拶</h1>
          <div className='message-section__grid'>
            <div className="message-section__left-div">
              <StaticImage src="../images/message/message_image.jpg" alt="president" />
            </div>
            <div className="message-section__right-div">
              <p className="message-section__text message-section__text-sp">
                  この度は、弊社ウェブサイトをご閲覧頂きありがとうございます。
              </p>
              <p className="message-section__text message-section__text-pc">
                  この度は、弊社ウェブサイトをご閲覧頂きありがとうございます。<br />
                  最後までお付き合い頂けますと幸いです。
              </p>
              <p className="message-section__text message-section__text-pc">
                私は、世の中に対し幅広く次の世代へと続くような技術、製品、サービスを提供するということをイメージし、
                「遍く（あまねく）」と「NEXT（ネクスト）」という言葉を繋げ、社名アマネクトへ表現しました。
              </p>
            </div>
            <div className='message-section__grid_full'>
              <p className="message-section__text message-section__text-sp">
                  最後までお付き合い頂けますと幸いです。
              </p>
              <p className="message-section__text message-section__text-sp">
                  私は、世の中に対し幅広く次の世代へと続くような技術、製品、サービスを提供するということをイメージし、
                  「遍く（あまねく）」と「NEXT（ネクスト）」という言葉を繋げ、社名アマネクトへ表現しました。
              </p>
              <p className="message-section__text">
                  弊社では、多岐多様にわたり常にアンテナを張り続け何事にもチャレンジし、実現する企業を目指しています。<br />
                  様々な事柄を実現するには個人、ひとりひとりでは限界があり、それぞれで得意分野も異なります。<br />
                  多方面の方々との出会いを大切に、各々の得意分野を生かし日々成長、発展しながら、お客様をはじめアマネクトと<br />
                  接する皆様と楽しみ続け幸せになれればと考えています。
              </p>
              <p className="message-section__text">
                  アマネクトは、そのための企業であり続けます。<br />
                  今日からの一歩！！そして未来へ・・・
              </p>
              <span className="message-section__top-name">代表取締役　畠山 政信</span>
            </div>
          </div>
        </article>
        <article className="message-section__container">
          <h1 className="message-section__title">ミッション</h1>
          <StaticImage src="../images/ant_mission.png" alt="amanect_mission_text" placeholder="none" />
        </article>
        <article className="message-section__container">
          <h1 className="message-section__title">ビジョン</h1>
          <StaticImage src="../images/ant_vision.png" alt="amanect_vision_text" placeholder="none" />
        </article>
      </div>
    </div>
  )
}

export default MessageSection
