import React from 'react'
import { Link } from 'gatsby'
import '@styles/object/project/business-section.scss'

const BusinessSection = () => {

  return (
    <div id="business" className="business-section">
      <div className="business-section__container-border">
        <article className="business-section__container">
          <h1 className="business-section__title">開発実績</h1>
          <p className="business-section__despricption">
            アマネクトの<br className="business-section__text-new-line"/>開発実績を紹介しております
          </p>
          <Link to="/business" className="business-section__go-business-page-btn">開発実績を見る</Link>
        </article>
      </div>
    </div>
  )
}

export default BusinessSection
