import React from 'react'
import '@styles/object/project/contact-section.scss'

const ContactSection = () => {

  return (
      <div id="contact" className="contact-section">
        <div className="contact-section__container-border">
          <article className="contact-section__container">
            <h1 className="contact-section__title">お問い合わせ</h1>
            <p>システム開発のご依頼、その他ご質問等、お気軽にお問い合わせください。</p>
            <div>
            <iframe title="contact-amanect-forms" src="https://docs.google.com/forms/d/e/1FAIpQLSfmG7-6ADmN0q-po0iVhYb0X1uPY3FM7FCcCuhV5DXYuFF1Yw/viewform?embedded=true" width="100%" height="1300" frameBorder="0" marginHeight="0" marginWidth="0" loading="lazy">読み込んでいます…</iframe>
            </div>
          </article>
        </div>
      </div>
  )
}

export default ContactSection
