import React from 'react'
import '@styles/object/project/findwork-section.scss'

const FindworkSection = () => {

  return (
      <div id="findwork" className="findwork-section">
        <div className="findwork-section__container-border">
          <article className="findwork-section__container">
            <h1 className="findwork-section__title">求人/採用の相談箱</h1>
            <p>
              採用活動（求人、キャリア採用、新卒採用、面接、転職など）に関するお悩みにお答えします。<br />
              気軽にご相談ください。<br />
              また、採用のご応募を受け付けております。気兼ねなくご連絡ください。<br />
              <a href="https://www.green-japan.com/company/9465" target="_blank" rel="noopener noreferrer" className="">
                https://www.green-japan.com/company/9465
              </a>
            </p>
            <div>
            <iframe title="findwork-amanect-forms" src="https://docs.google.com/forms/d/e/1FAIpQLSfp_XQ6HHRcPHTvHOeV1GjeSn5KQPDQ2KKk6CTIl66acSC9dw/viewform?embedded=true" width="100%" height="1300" frameBorder="0" marginHeight="0" marginWidth="0" loading="lazy">読み込んでいます…</iframe>
            </div>
          </article>
        </div>
      </div>
  )
}

export default FindworkSection
