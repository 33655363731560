import React from "react"
import "@styles/object/project/overview-section.scss"

const OverviewSection = () => {
  return (
    <div id="overview" className="overview-section">
      <div className="overview-section__container-border">
        <article className="overview-section__container">
          <h1 className="overview-section__title">会社概要</h1>
          <table className="overview-section__table">
            <tbody>
              <tr>
                <th className="overview-section__table-head">会社名</th>
                <td className="overview-section__table-data">
                  株式会社アマネクト
                </td>
              </tr>
              <tr>
                <th className="overview-section__table-head">代表</th>
                <td className="overview-section__table-data">畠山&nbsp;政信</td>
              </tr>
              <tr>
                <th className="overview-section__table-head">所在地</th>
                <td className="overview-section__table-data">
                  &#12306;221-0835
                  <br />
                  神奈川県横浜市神奈川区鶴屋町2丁目9-22
                  <br />
                  日興パレス&nbsp;208号室
                  <iframe
                    title="amanect-map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3249.3964517067466!2d139.6191779154988!3d35.46973368024422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60185c104ebea623%3A0x62a966df52eb3a22!2z44CSMjIxLTA4MzUg56We5aWI5bed55yM5qiq5rWc5biC56We5aWI5bed5Yy66ba05bGL55S677yS5LiB55uu77yZ4oiS77yS77yS!5e0!3m2!1sja!2sjp!4v1562848127210!5m2!1sja!2sjp"
                    width="95%"
                    height="400"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                  ></iframe>
                </td>
              </tr>
              <tr>
                <th className="overview-section__table-head">電話番号</th>
                <td className="overview-section__table-data">
                  TEL：045-534-3818
                  <br />
                  FAX：045-534-3819
                </td>
              </tr>
              <tr>
                <th className="overview-section__table-head">設立</th>
                <td className="overview-section__table-data">2019年7月</td>
              </tr>
              <tr>
                <th className="overview-section__table-head">資本金</th>
                <td className="overview-section__table-data">300万円</td>
              </tr>
              <tr>
                <th className="overview-section__table-head">従業員数</th>
                <td className="overview-section__table-data">7名</td>
              </tr>
            </tbody>
          </table>
        </article>
      </div>
    </div>
  )
}

export default OverviewSection
