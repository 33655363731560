import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import '@styles/object/project/info-section.scss'

const InfoSection = () => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulInfo (
        sort: {
          fields: date,
          order: DESC
        }
      ) {
        edges {
          node {
            id
            title
            date (formatString: "YYYY年M月D日")
            path: date (formatString: "YYYY-MM-DD")
          }
        }
      }
    }
  `)

  const edges = data.allContentfulInfo.edges
  let sameDateCount = 0

  for (let i = 0; i < edges.length; i++) {

    let slug = `/info/${edges[i].node.path}/`
    if (edges[i + 1] && edges[i].node.path === edges[i + 1].node.path) {
      slug = `${slug}${++sameDateCount}/`
    } else if (sameDateCount > 0) {
      slug = `${slug}${++sameDateCount}/`
      sameDateCount = 0
    }
    edges[i].node.slug = slug
  }

  return (
    <div id="info" className="info-section">
      <div className="info-section__container-border">
        <article className="info-section__container">
          <h1 className="info-section__title">お知らせ</h1>
          <ul className="info-section__list">
          {edges.map(({node}) => {
            return (
              <li key={node.id} className="info-section__list-item-wrapper">
                <Link to={node.slug} className="info-section__list-item-link">
                  <dl className="info-section__list-item">
                    <dt className="info-section__list-item-publish-date">{node.date}</dt>
                    <dd className="info-section__list-item-title">{node.title}</dd>
                  </dl>
                </Link>
              </li>
            )
          })}
          </ul>
        </article>
      </div>
    </div>
  )
}

export default InfoSection
